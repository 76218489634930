import { Injectable } from "@angular/core";
import { DataService } from './DataServices/data.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { IResponse } from '../model/response.interface';
import { LeadClientsRepository } from './lead.clients.repository';
import { LangsRepository } from './langs.repository';
import { GetLeadClientByCrmTokenResponse } from "../model/leadClients/get.lead.client.bycrmtoken.response";
import { LeadClient } from '../model/leadClients/lead.client.model';
import { LeadClientsList } from '../model/leadClients/lead.clients.list.interface';
import { LeadDeal } from '../model/leadClients/lead.client.deal';
import { LeadClientMeeting } from '../model/leadClients/lead.client.meeting';
import { Lead } from "../model/lead.model";
import { LeadClientMeetingStatus } from "../model/leadClients/lead.client.meeting.meetingstatus";

@Injectable()
export class LeadClientRepositoryBUT extends LeadClientsRepository {
    public lastReload: number = 0;
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public leadClientsReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        public dataService: DataService,
        protected langsRepository: LangsRepository
    ) {
        super(dataService, langsRepository);
        this.dataService.token.subscribe(token => {
            if (token) {
                this.ready.next(true);
            } else {
                this.leadCLients = [];
                this.ready.next(false);
            }
        })
    }

    public getLeadClientById(id: number): LeadClient {
        return this.leadCLients.find(x => x.id === id) || null;
    }

    public invalidate(): void {
        this.lastReload = 0;
    }

    public loadLeadClients(from: number, q: number, search: string, filter: number, sort: string, dir: number, processId: number): Observable<IResponse<LeadClientsList>> {
        this.setLeadClientsReady(false);
        return this.dataService.but_getLeadClients(from, q, search, filter, sort, dir, processId);
    }

    public loadLeadClient(id: number): Observable<IResponse<LeadClient>> {
        return this.dataService.but_getLeadClient(id);
    }

    public sendUpdateLeadClientFollowup(id: number, followUp: string): Observable<IResponse<any>> {
        return this.dataService.but_updateLeadClientFollowUp(id, followUp);
    }

    public sendUpdateLeadClientStatus(leadClient: LeadClient): Observable<IResponse<any>> {
        return this.dataService.but_updateLeadClientStatus(leadClient);
    }

    public sendUpdateLeadClient(id: number, leadClient: LeadClient) {
        return this.dataService.but_updateLeadClient(leadClient);
    }

    public sendAddLeadClient(leadClient: LeadClient): Observable<IResponse<number>> {
        return this.dataService.but_addLeadClient(leadClient);
    }

    public sendLeadClientDeal(dealId: number, leadClientId: number, subject: string, amount: number, assigneeId: number, createdDate: string): Observable<IResponse<number>> {
        return this.dataService.but_updateLeadClientDeal(dealId, leadClientId, subject, amount, assigneeId, createdDate);
    }

    public sendLeadClientUpdateDeal(dealId: number, leadClientId: number, subject: string, amount: number, assigneeId: number): Observable<IResponse<number>> {
        return this.dataService.but_UpdateDeal(dealId, leadClientId, subject, amount, assigneeId);
    }

    public getLeadClientDeal(id: number): Observable<IResponse<LeadDeal>> {
        return this.dataService.but_getDealById(id);
    }

    public getLeadClientMeeting(id: number): Observable<IResponse<LeadClientMeeting>> {
        return this.dataService.but_LeadClientGetMeeting(id);
    }

    public CancelLeadClientMeeting(id: number, locale: string): Observable<IResponse<boolean>> {
        return this.dataService.but_LeadClientCancelMeeting(id, locale);
    }

    public CancelLeadClientDeal(id: number): Observable<IResponse<boolean>> {
        return this.dataService.but_LeadClientCancelDeal(id);
    }

    public addMeeting(id: number, leadClientId: number, meetingDate: string, meetingEndDate: string, subject: string, meetingAssigneeId: number, externalId: number,
            note: string, meetingStatus: LeadClientMeetingStatus) {
        return this.dataService.but_LeadClientAddMeeting(id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus);
    }

    public updateMeeting(id: number, leadClientId: number, meetingDate: string, meetingEndDate: string, subject: string, meetingAssigneeId: number, externalId: number,
            note: string, meetingStatus: LeadClientMeetingStatus) {
        return this.dataService.but_LeadClientUpdateMeeting(id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus);
    }

    public getNotes(id: number) {
        return this.dataService.but_getLeadClientNotes(id);
    }

    public addNote(leadClientId: number, note: string, assigneeId: number, gpsLongitude: string, gpsLatitude: string, createdDate: string) {
        return this.dataService.but_addLeadClientNote(leadClientId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate);
    }

    public getLeadNotes(leadId: number) {
        return this.dataService.but_getLeadNotes(leadId);
    }

    public addLeadNote(leadId: number, note: string, assigneeId: number, gpsLongitude: string, gpsLatitude: string, createdDate: string) {
        return this.dataService.but_addLeadNote(leadId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate);
    }

    public getLeads(leadClientId: number): Observable<IResponse<Lead[]>> {
        return this.dataService.but_getLeadsForLeadClient(leadClientId);
    }

    public getLead(leadId: number): Observable<IResponse<Lead>> {
        return this.dataService.but_getLead(leadId);
    }

    public getDeals(leadClientId: number): Observable<IResponse<LeadDeal[]>> {
        return this.dataService.but_getDealsForLeadClient(leadClientId);
    }

    public getMeetings(leadClientId: number): Observable<IResponse<LeadClientMeeting[]>> {
        return this.dataService.but_getMeetingsForLeadClient(leadClientId);
    }

    public getAllWhatsappMessagesLeadClient(leadClientId: number, phone?: string) {
        return this.dataService.getAllWhatsappMessagesLeadClient(leadClientId, phone);
    }

    public getMessageFile(url: string): Promise<Blob> {
        return this.dataService.getMessageFile(url);
    }

    public getTemplatesForLeadClient(leadClientId: number, phone?: string) {
        return this.dataService.getTemplatesForLeadClient(leadClientId, phone);
    }

    public markMessagesAsRead(leadClientId: number) {
        return this.dataService.markMessagesAsRead(leadClientId);
    }

    public sendReplyWhatsappMessage(leadClientId: number, message: string, userIdentityName: string, files?: File[], whatsappPhone?: string): Observable<IResponse<any>> {
        if (files && files.length > 0) {
            return this.dataService.sendReplyWhatsappFile(leadClientId, userIdentityName, files, whatsappPhone);
        }

        return this.dataService.sendReplyWhatsappMessage(leadClientId, message, userIdentityName, whatsappPhone);
    }

    public sendTemplateWhatsappMessage(leadClientId: number, templateName: string, userIdentityName: string, whatsappPhone: string): Observable<IResponse<any>> {
        return this.dataService.sendTemplateWhatsappMessage(leadClientId, templateName, userIdentityName, whatsappPhone);
    }

    public setLeadClientsReady(value: boolean): void {
        this.leadClientsReady.next(value);
    }

    public updateLeadClientAddress(leadClientId: number, address: string, addressCoords: string): Observable<IResponse<any>> {
        return this.dataService.updateLeadClientAddress(leadClientId, address, addressCoords);
    }

    public getAdPreview(channelId: number, adGroupId: string, adId: string): Observable<IResponse<string>> {
        return this.dataService.but_getAdPreview(channelId, adGroupId, adId);
    }

    //#region WithCrmToken

    public getAllWhatsappMessagesLeadClientWithCrmToken(token: string, leadClientId: number, phone?: string) {
        return this.dataService.getAllWhatsappMessagesLeadClientWithCrmToken(token, leadClientId, phone);
    }

    public getMessageFileWithCrmToken(token: string, url: string) : Promise<Blob> {
        return this.dataService.getMessageFileWithCrmToken(token, url);
    }

    public getLeadClientWithCrmToken(token: string, id: number): Observable<IResponse<GetLeadClientByCrmTokenResponse>>{
        return this.dataService.getLeadClientWithCrmToken(token, id);
    }

    public getTemplatesForLeadClientWithCrmToken(token: string, leadClientId: number, phone?: string) {
        return this.dataService.getTemplatesForLeadClientWithCrmToken(token, leadClientId, phone);
    }

    public markMessagesAsReadWithCrmToken(token: string, leadClientId: number) {
        return this.dataService.markMessagesAsReadWithCrmToken(token, leadClientId);
    }

    public sendReplyWhatsappMessageWithCrmToken(
        token: string,
        leadClientId: number,
        message: string,
        userIdentityName: string,
        files: File[],
        whatsappPhone?: string
    ): Observable<IResponse<any>> {
        if (files && files.length > 0) {
            return this.dataService.sendReplyWhatsappFileMessageWithCrmToken(token, leadClientId, message, userIdentityName, files, whatsappPhone);
        }

        return this.dataService.sendReplyWhatsappMessageWithCrmToken(token, leadClientId, message, userIdentityName, whatsappPhone);
    }

    public sendTemplateWhatsappMessagetWithCrmToken(
        token: string,
        leadClientId: number,
        templateName: string,
        userIdentityName: string,
        whatsappPhone: string
    ): Observable<IResponse<any>> {
        return this.dataService.sendTemplateWhatsappMessageWithCrmToken(token, leadClientId, templateName, userIdentityName, whatsappPhone);
    }

    public getAdPreviewWithCrmToken(token: string, channelId: number, adGroupId: string, adId: string): Observable<IResponse<string>> {
        return this.dataService.but_getAdPreviewWithCrmToken(token, channelId, adGroupId, adId);
    }

    //#endregion WithCrmToken
}