import { LeadPermission } from './leadpermission.interface';
import { Model } from './model';
import { WhatsappPhoneListItem } from './whatsapp-phone-list-item';

export class User extends Model {
    constructor(
        public canDeleteLeads?: boolean,
        public canEditAllLeads?: boolean,
        public canViewAllLeads?: boolean,
        public canViewEmail?: boolean,
        public canViewAssignee?: boolean,
        public canViewDealsAmount?: boolean,
        public canViewFullname?: boolean,
        public canViewOtherData?: boolean,
        public canViewPhone?: boolean,
        public canViewPrice?: boolean,
        public email?: string,
        public firstName?: string,
        public id?: number,
        public isAdmin?: boolean,
        public isDefaultAssignee?: boolean,
        public lastName?: string,
        public phone?: string,
        public emailError?: string,
        public phoneError?: string,
        public firstNameError?: string,
        public lastNameError?: string,
        public clientHasCRM?: boolean,
        public replaceUserId?: number,
        public sendPushNewLead?: boolean,
        public sendPushFollowUp?: boolean,
        public sendMeetingCreatedEmail?: boolean,
        public sendMeetingCanceledEmail?: boolean,
        public isPushNotifications?: boolean,
        public activeRestrictions?: LeadPermission[],
        public whatsappPhones?: WhatsappPhoneListItem[],
    ) {
        super();
    }

    public init(): User {
        this.canDeleteLeads = true;
        this.canEditAllLeads = true;
        this.canViewAllLeads = true;
        this.canViewEmail = true;
        this.canViewAssignee = true;
        this.canViewDealsAmount = true;
        this.canViewFullname = true;
        this.canViewOtherData = true;
        this.canViewPhone = true;
        this.canViewPrice = true;
        this.email = "";
        this.firstName = "";
        this.isAdmin = false;
        this.isDefaultAssignee = false;
        this.lastName = "";
        this.phone = "";
        this.emailError = "";
        this.phoneError = "";
        this.firstNameError = "";
        this.lastNameError = "";
        this.clientHasCRM = false;
        this.replaceUserId = 0;
        this.sendPushNewLead = true;
        this.sendPushFollowUp = true;
        this.sendMeetingCreatedEmail = true;
        this.sendMeetingCanceledEmail = true;
        this.isPushNotifications = true;
        this.activeRestrictions = [];
        
        return this;
    }
}
